import React, { Component } from "react";
import { Link } from "react-router-dom";
import HeroMainRow from "./HeroMainRow";
import { useTranslation } from 'react-i18next';
// Images

import covervid from "../../../images/swissmain.mp4";
import posterImage from "../../../images/cover.jpg";

const HeroBanner = (props) => {

  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="VideoBanner">
          <video loop autoplay="" muted poster={posterImage}>
            <source src={covervid} type="video/mp4" />
            Your browser does not support the video tag. I suggest you upgrade
            your browser.
          </video>
          <div className="BannerContent">
            <div className="container">
              <div className="ContentBoxt">
                <div className="T1">
                  <span>{t('homehero-banner.banner-title')}</span>
                </div>
                <div className="T2">
                {t('homehero-banner.sub-text')}
                </div>
                <div className="BtnMobile">
                  <a class="btn btn-primary" href="tel:+966920015259">
                  {t('homehero-banner.call-button')}
                  </a>
                  <Link class="btn btn-secondary ms-3 hmViewButton" to="/services">
                  {t('homehero-banner.services-button')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HeroMainRow/>
      </div>
    </>
  );
};

export default HeroBanner;