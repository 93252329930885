import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// Images
import bg2 from "../../../images/background/bg1.png";

function ServicesNew() {

  const { t } = useTranslation();

    return (
      <>
        <section
          className="section-area section-sp2 bg-gray"
          style={{
            backgroundImage: "url(" + bg2 + ")",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="heading-bx">
                  <h2 className="title">{t('home-services-section.title')}</h2>
                  <p>{t('home-services-section.subtitle')}</p>
                </div>
              </div>

            </div>
            <div className="ServiceWrap">
              <div className="row">
                <div className="col-md-3">
                  <div className="ServiceBoxR1 service1">
                    <div>
                      <div className="SerT1">{t('home-services-section.service-title-1')}</div>
                      
                      <Link class="SerT2 btn btn-primary" to="/services/#s2">
                      {t('home-services-section.btn')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="ServiceBoxR1 service2">
                    <div>
                      <div className="SerT1">{t('home-services-section.service-title-2')}</div>
                      
                      <Link class="SerT2 btn btn-primary" to="/services/#s3">
                      {t('home-services-section.btn')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="ServiceBoxR1 service8">
                    <div>
                      <div className="SerT1">{t('home-services-section.service-title-3')}</div>
                      
                      <Link class="SerT2 btn btn-primary" to="/services/other-services/electric-car-repair">
                      {t('home-services-section.btn')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="ServiceBoxR1 service3">
                    <div>
                      <div className="SerT1">{t('home-services-section.service-title-4')}</div>
                      
                      <Link class="SerT2 btn btn-primary" to="/packages/major-service">
                      {t('home-services-section.btn')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="ServiceBoxR1 service4">
                    <div>
                      <div className="SerT1">{t('home-services-section.service-title-5')}</div>
                      
                      <Link class="SerT2 btn btn-primary" to="/packages/minor-service">
                      {t('home-services-section.btn')}
                      </Link>
                    </div>
                  </div>
                </div>
                
                <div className="col-md-3">
                  <div className="ServiceBoxR1 service6">
                    <div>
                      <div className="SerT1">{t('home-services-section.service-title-6')}</div>
                      
                      <Link class="SerT2 btn btn-primary" to="/services/#s1">
                      {t('home-services-section.btn')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="ServiceBoxR1 service7">
                    <div>
                      <div className="SerT1">{t('home-services-section.service-title-7')}</div>
                      
                      <Link class="SerT2 btn btn-primary" to="/services/other-services/car-recovery">
                      {t('home-services-section.btn')}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="ServiceBoxR1 service5">
                    <div>
                      <div className="SerT1">{t('home-services-section.service-title-8')}</div>
                      
                      <Link class="SerT2 btn btn-primary" to="/packages/service-contract">
                      {t('home-services-section.btn')}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );

}

export default ServicesNew;
