import React, { useState, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

const JobApplicationForm = () => {
  //const { t, i18n } = useTranslation();

  // Form field states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [vacancy, setVacancy] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [file, setFile] = useState(null);

  // Additional states
  const [vacancies, setVacancies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  // Error states
  const [errors, setErrors] = useState({});

  // Reference to file input for resetting
  const fileInputRef = useRef(null);

  const history = useHistory();
  const { t: translate } = useTranslation();

  const allVacancies = {
    Riyadh: [
      { title: translate("vacancyriyadh.List1") },
      { title: translate("vacancyriyadh.List2") },
      { title: translate("vacancyriyadh.List3") },
      { title: translate("vacancyriyadh.List4") },
      { title: translate("vacancyriyadh.List5") },
      { title: translate("vacancyriyadh.List6") },
      { title: translate("vacancyriyadh.List7") },
      { title: translate("vacancyriyadh.List8") },
      { title: translate("vacancyriyadh.List9") },
      { title: translate("vacancyriyadh.List10") },
      { title: translate("vacancyriyadh.List11") },
      { title: translate("vacancyriyadh.List12") },
      { title: translate("vacancyriyadh.List13") },
      { title: translate("vacancyriyadh.List14") },
      { title: translate("vacancyriyadh.List15") },
      { title: translate("vacancyriyadh.List16") },
      { title: translate("vacancyriyadh.List17") },
      { title: translate("vacancyriyadh.List18") },
      { title: translate("vacancyriyadh.List19") },
      { title: translate("vacancyriyadh.List20") },
      { title: translate("vacancyriyadh.List21") },
      { title: translate("vacancyriyadh.List22") },
      { title: translate("vacancyriyadh.List23") },
      { title: translate("vacancyriyadh.List24") },
      { title: translate("vacancyriyadh.List25") },
      { title: translate("vacancyriyadh.List26") },
      { title: translate("vacancyriyadh.List27") },
      { title: translate("vacancyriyadh.List28") },
    ],
    Jeddah: [
      { title: translate("vacancyjeddah.List1") },
      { title: translate("vacancyjeddah.List2") },
      { title: translate("vacancyjeddah.List3") },
      { title: translate("vacancyjeddah.List4") },
      { title: translate("vacancyjeddah.List5") },
      { title: translate("vacancyjeddah.List6") },
      { title: translate("vacancyjeddah.List7") },
      { title: translate("vacancyjeddah.List8") },
      { title: translate("vacancyjeddah.List9") },
      { title: translate("vacancyjeddah.List10") },
      { title: translate("vacancyjeddah.List11") },
      { title: translate("vacancyjeddah.List12") },
      { title: translate("vacancyjeddah.List13") },
      { title: translate("vacancyjeddah.List14") },
      { title: translate("vacancyjeddah.List15") },
      { title: translate("vacancyjeddah.List16") },
      { title: translate("vacancyjeddah.List17") },
      { title: translate("vacancyjeddah.List18") },
      { title: translate("vacancyjeddah.List19") },
      { title: translate("vacancyjeddah.List20") },
      { title: translate("vacancyjeddah.List21") },
      { title: translate("vacancyjeddah.List22") },
      { title: translate("vacancyjeddah.List23") },
      { title: translate("vacancyjeddah.List24") },
      { title: translate("vacancyjeddah.List25") },
      { title: translate("vacancyjeddah.List26") },
      { title: translate("vacancyjeddah.List27") },
      { title: translate("vacancyjeddah.List28") },
      { title: translate("vacancyjeddah.List29") },
      { title: translate("vacancyjeddah.List30") },
      { title: translate("vacancyjeddah.List31") },
      { title: translate("vacancyjeddah.List32") },
      { title: translate("vacancyjeddah.List33") },
    ],
  };

  // Added

  // Handle changes for each input
  const handleNameChange = (e) => {
    setName(e.target.value);
    setErrors((prev) => ({ ...prev, name: "" }));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors((prev) => ({ ...prev, email: "" }));
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setErrors((prev) => ({ ...prev, phone: "" }));
  };

  const handleLocationChange = (e) => {
    const selectedLocation = e.target.value;
    setLocation(selectedLocation);
    if (allVacancies[selectedLocation]) {
      setVacancies(allVacancies[selectedLocation]);
    } else {
      setVacancies([]);
    }
    setVacancy("");
    setErrors((prev) => ({ ...prev, location: "" }));
  };

  const handleVacancyChange = (e) => {
    setVacancy(e.target.value);
    setErrors((prev) => ({ ...prev, vacancy: "" }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      // Validate file type
      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors((prev) => ({
          ...prev,
          file: "Only PDF, DOC, and DOCX files are allowed.",
        }));
        setFile(null);
        return;
      }

      // Validate file size (500 KB)
      const maxSize = 500 * 1024; // 500 KB in bytes
      if (selectedFile.size > maxSize) {
        setErrors((prev) => ({
          ...prev,
          file: "File size must be 500 KB or less.",
        }));
        setFile(null);
        return;
      }

      // If validation passes
      setFile(selectedFile);
      setErrors((prev) => ({ ...prev, file: "" }));
    } else {
      setFile(null);
      setErrors((prev) => ({ ...prev, file: "Please select a file." }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset previous messages and errors
    setMessage("");
    setErrors({});

    // Validation
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Please enter your name.";
    }

    if (!email.trim()) {
      newErrors.email = "Please enter your email address.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!phone.trim()) {
      newErrors.phone = "Please enter your phone number.";
    } else if (!/^\d+$/.test(phone)) {
      newErrors.phone = "Phone number must contain only numbers.";
    }

    if (!location) {
      newErrors.location = "Please select a location.";
    }

    if (!vacancy) {
      newErrors.vacancy = "Please select a vacancy.";
    }

    if (!file) {
      newErrors.file = "Please select a file to upload.";
    }

    // If there are errors, set them and abort submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setMessage("Please fix the errors above and try again.");
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("name", name.trim());
    formData.append("email", email.trim());
    formData.append("phone", phone.trim());
    formData.append("location", location);
    formData.append("vacancy", vacancy);
    formData.append("file", file);

    try {
      setLoading(true);
      setMessage("");
      const response = await axios.post(
        "https://swissemail.vercel.app/api/s3-upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.fileUrl) {
        setFileUrl(response.data.fileUrl);
        setMessage("Thank You! Your resume has been successfully submitted.");

        // Reset form fields
        setName("");
        setEmail("");
        setPhone("");
        setLocation("");
        setVacancy("");
        setFile(null);
        setVacancies([]);

        // Clear the file input using ref
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }

        // Optionally, redirect the user or perform other actions
        // history.push('/success');
      } else {
        setMessage("Error Submitting.");
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data && error.response.data.error) {
        setMessage(`Error: ${error.response.data.error}`);
      } else {
        setMessage("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  const { t } = useTranslation();

  return (
    <div>
      <section className="section-area section-sp2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <form className="contact-form ajax-form jobForm" onSubmit={handleSubmit}>
                <div className="heading-bx mb-20">
                  <h6 className="title-ext text-primary">
                    {t("jobapp.title")}
                  </h6>
                  <h2 className="title mb-0">{t("jobapp.upload")}</h2>
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          name="name"
                          type="text"
                          className={`form-control valid-character ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          placeholder={t("jobapp.name")}
                          value={name}
                          onChange={handleNameChange}
                          required
                        />
                        {errors.name && (
                          <div className="invalid-feedback">{errors.name}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6">
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          name="email"
                          type="email"
                          className={`form-control ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          value={email}
                          onChange={handleEmailChange}
                          placeholder={t("jobapp.email")}
                          required
                        />
                        {errors.email && (
                          <div className="invalid-feedback">{errors.email}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          name="phone"
                          type="text"
                          className={`form-control int-value ${
                            errors.phone ? "is-invalid" : ""
                          }`}
                          placeholder={t("jobapp.phone")}
                          value={phone}
                          onChange={handlePhoneChange}
                          required
                        />
                        {errors.phone && (
                          <div className="invalid-feedback">{errors.phone}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 custSelect">
                    <div className="form-group">
                      <div className="input-group">
                        <select
                          name="location"
                          value={location}
                          onChange={handleLocationChange}
                          className={`form-control ${
                            errors.location ? "is-invalid" : ""
                          }`}
                          required
                        >
                          <option value="">Select Location</option>
                          <option value="Riyadh">Riyadh</option>
                          <option value="Jeddah">Jeddah</option>
                        </select>
                        {errors.location && (
                          <div className="invalid-feedback">
                            {errors.location}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-6 custSelect">
                    <div className="form-group">
                      <div className="input-group">
                        <select
                          name="vacancy"
                          value={vacancy}
                          onChange={handleVacancyChange}
                          className={`form-control ${
                            errors.vacancy ? "is-invalid" : ""
                          }`}
                          required
                        >
                          <option value="">Select Vacancy</option>
                          {vacancies.map((vacancyItem, index) => (
                            <option key={index} value={vacancyItem.title}>
                              {vacancyItem.title}
                            </option>
                          ))}
                        </select>
                        {errors.vacancy && (
                          <div className="invalid-feedback">
                            {errors.vacancy}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <div className="input-group">
                        <input
                          type="file"
                          id="file"
                          name="file"
                          onChange={handleFileChange}
                          accept=".pdf,.doc,.docx"
                          required
                          className={`form-control ${
                            errors.file ? "is-invalid" : ""
                          }`}
                          placeholder={t("jobapp.file")}
                          ref={fileInputRef}
                        />
                        {errors.file && (
                          <div className="invalid-feedback">{errors.file}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 cntTextCenter">
                    <button
                      name="submit"
                      type="submit"
                      disabled={loading}
                      className="btn btn-primary btn-lg"
                    >
                      {loading ? "Uploading..." : t("jobapp.send")}
                    </button>

                    {message && (
                      <p
                        className={`cstSetMessage`}
                      >
                        {message}
                      </p>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JobApplicationForm;
